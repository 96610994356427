.App {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative; */
  margin: 20px auto;
  text-align: center;
  max-width: 1100px;
  /* max-height: 800px */
}

.hover:hover {
  /* opacity: .5; */
  cursor: pointer;
  background-color: white;
  color: #4F5AA1
}

.no-prj-link-text {
  visibility: hidden;
  width: 200px;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
}

.disabled:hover .no-prj-link-text {
  cursor: not-allowed;
  background-color: #4F5AA1;
  color: white;
  visibility: visible;
}

/* nav */
.nav-wrapper {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
}

.nav-btn {
  margin: 20px;
  text-decoration: none;
  color: black;
  font-size: .7rem;
  line-height: 1.6em;
  letter-spacing: 2px;
}

.nav-btn:hover{
  color:#4F5AA1; 
}

/* home */
.landing-wrapper {
  background-color: #4F5AA1;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.landing-text {
  color: white;
  font-size: 1.8rem;
  letter-spacing: 3px;
  padding-top: 100px;
  font-weight: 300;
}

.landing-text-skills {
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 70px;
}

.lts {
  margin: 0 10px
}

.tech {
  /* flex-grow: 1; */
  display: flex;
  /* flex-basis: 40%; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.width-flex {
  width: 100%;
  font-weight: 600;
  color: #4F5AA1
}

.icon-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.devicon {
  font-size: 2.5rem;
  margin: 0 20px;
}

.devicon2 {
  font-size: 1.5rem;
  margin: 0 10px;
}


/*about*/
.abt-text {
  margin: 0 10%;
  text-align: left
}

/*work*/
.project-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  padding: 20px 0
}

.project-title {
  padding: 5px 0
}

.ind-prj-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.img-container {
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  justify-content: center;
}

.img-container-single {
  display: flex;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  justify-content: center;
}

.prj-img {
  width: 210px;
  height: 375px;
  margin:10px 10px 10px 0 /*trbl*/
  /* margin: 5px; */
}

.prj-img-single {
  width: 99%
}

.prj-img-singleb {
  display: none
}

.prj-abt {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.prj-title {
  font-size: 1.5rem;
  line-height: 1.6em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  text-decoration: none;
  font-weight: 300;
  margin: 0
}

.prj-button-container {
  margin: 20px 0;
}

.prj-button {
  color: white;
  background-color: #4F5AA1;
  font-size: 15px;
  text-decoration: none;
  margin: 10px;
  border-radius: 25px;
}

.btn-live {
  padding: 10px
}

.btn-code {
  padding: 10px 25px
}

.techwork {
  font-size: .8em
}

/*footer*/
.footer {
  /* position: absolute;
  bottom: 0; */
  border: none;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
  padding: 20px 0;
  /* margin: 20px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  font-size: .7rem;
  line-height: 1.6em;
  letter-spacing: 2px;
}

.footer-links {
  display: flex
}

.footer-icons {
  color: black;
  font-size: 1.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer-icons:hover{
  color:#4F5AA1; 
}

/*tablet response*/
@media (max-width: 800px) {
  .abtimg {
    max-width: 90%
  }

  .project-container {
    display: flex;
    flex-direction: column;
  }

  .img-container {
    width: 70%;
    justify-content: center;
  }

  .prj-abt {
    width: 90%;
    margin: 0 20px;
  }
}

/*mobile response*/
@media (max-width: 500px) {
  .abtimg {
    max-width: 90%;
  }

  .project-container {
    display: flex;
    flex-direction: column;
  }

  .img-container {
    flex-wrap: wrap
  }

  .prj-img {
    width: 320px;
    height: 570px;
    margin:10px 5px
  }

  /* .prj-img-single {
    display: none
  } */

  .prj-img-singleb {
    display: inline;
    margin:10px 0
  }

  .prj-abt {
    margin: 0 10px
  }
}
